var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.state.valorEnviado === false)?_c('v-navigation-drawer',_vm._b({attrs:{"id":"core-navigation-drawer","dark":_vm.barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)',"expand-on-hover":_vm.expandOnHover,"right":_vm.$vuetify.rtl,"src":_vm.barImage,"mobile-breakpoint":"960","app":"","width":"260"},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_c('v-img',_vm._b({attrs:{"gradient":`to top, ${_vm.barColor}`}},'v-img',props,false))]}},{key:"append",fn:function(){return [_c('base-item',{staticClass:"text-subtitle-1",attrs:{"item":{
        title: 'Cerrar sesión',
        icon: 'mdi-account',
        to: '/cerrar'
      }}})]},proxy:true}],null,false,3410942239),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),[_c('v-divider',{staticClass:"mb-1"}),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"align-self-center",attrs:{"color":"white","contain":""}},[_c('v-img',{attrs:{"src":"assets/favicon.png","max-height":"50"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4",domProps:{"textContent":_vm._s(_vm.profile.title)}}),_c('v-list-item-title',{staticClass:"text-h6",domProps:{"textContent":_vm._s(_vm.nombre)}})],1)],1)],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-list',{attrs:{"expand":"","nav":""}},[[_c('base-item',{attrs:{"item":{
          icon: 'mdi-clipboard-list',
          title: 'Registro',
          to: '/registro',
        }}}),_c('base-item',{attrs:{"item":{
          icon: 'mdi-file-excel',
          title: 'Registro masivo',
          to: '/registro_masivo',
        }}}),(_vm.tipoUsuario === true)?_c('base-item',{attrs:{"item":{
          icon: 'mdi-account',
          title: 'Administración de usuarios',
          to: '/usuarios',
        }}}):_vm._e(),(_vm.tipoUsuario === true)?_c('base-item',{attrs:{"item":{
          icon: 'mdi-gavel',
          title: 'Administración de juzgados',
          to: '/juzgados',
        }}}):_vm._e(),_c('base-item',{staticClass:"text-h1",attrs:{"item":{
          icon: 'mdi-lock',
          title: 'Cambio de clave',
          to: '/clave',
        }}})]],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }