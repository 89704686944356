<template>
  <v-navigation-drawer
    v-if="$store.state.valorEnviado === false"
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template #img="props">
      <v-img
        :gradient="`to top, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src="assets/favicon.png"
            max-height="50"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-h4"
            v-text="profile.title"
          />
          <v-list-item-title
            class="text-h6"
            v-text="nombre"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- eslint-disable-next-line -->
      <template>
        <base-item
          :item="{
            icon: 'mdi-clipboard-list',
            title: 'Registro',
            to: '/registro',
          }"
        />
        <base-item
          :item="{
            icon: 'mdi-file-excel',
            title: 'Registro masivo',
            to: '/registro_masivo',
          }"
        />
        <base-item
          v-if="tipoUsuario === true"
          :item="{
            icon: 'mdi-account',
            title: 'Administración de usuarios',
            to: '/usuarios',
          }"
        />
        <base-item
          v-if="tipoUsuario === true"
          :item="{
            icon: 'mdi-gavel',
            title: 'Administración de juzgados',
            to: '/juzgados',
          }"
        />
        <base-item
          class="text-h1"
          :item="{
            icon: 'mdi-lock',
            title: 'Cambio de clave',
            to: '/clave',
          }"
        />
      </template>
    </v-list>
    <template #append>
      <base-item
        class="text-subtitle-1"
        :item="{
          title: 'Cerrar sesión',
          icon: 'mdi-account',
          to: '/cerrar'
        }"
      />
    </template>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      titulo: 'JUEZ DE PAZ',
      nombre: '',
      distrito: '',
      tipoUsuario: false,
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'produccion',
          to: '/',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          localStorage.getItem('usuarioOdajup') ? this.distritro = JSON.parse(localStorage.getItem('usuarioOdajup')).distrito : this.distrito = ''
          localStorage.getItem('usuarioOdajup') ? (JSON.parse(localStorage.getItem('usuarioOdajup')).tipo_usuario === 'E' ? this.tipoUsuario = true : this.tipoUsuario = false) : this.tipoUsuario = false
          this.nombre = JSON.parse(localStorage.getItem('usuarioOdajup')) ? JSON.parse(localStorage.getItem('usuarioOdajup')).distrito : ''
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>
